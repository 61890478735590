<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="4">
								Semua Aropt
							</CCol>
							<CCol md="8" class="text-right">
								<router-link :to="{path: 'add-aropt'}" class="btn btn-success btn-sm"><i class="fa fa-plus"></i> Tambah</router-link>
							</CCol>
						</CRow>
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol sm="12">
								<CRow>
									<CCol sm="12">
										<div class="row">
										<div class="col-md-11">
											<div class="row">
												<div class="col-md">
													<div class="form-group">
														<b for=""><b>Kelompok Jenis Tanaman <span class="text-danger">*</span></b></b>
														<v-select :options="list_tanaman" label="tk_nama" v-model="kelompok_selected" placeholder="Pilih Kelompok Jenis Tanaman"></v-select>
													</div>
												</div>
												<div class="col-md">
													<div class="form-group">
														<b for="">Sub Kelompok Jenis Tanaman</b>
														<v-select :options="kelompok_selected.tanaman_kategori_sub" label="tks_nama" v-model="subkelompok_selected" placeholder="Pilih Sub Kelompok Jenis Tanaman"></v-select>
													</div>
												</div>
												<div class="col-md">
													<div class="form-group">
														<b for="">Bentuk Benih</b>
														<v-select :options="list_bentuk_benih" label="bb_nama" v-model="bentuk_benih_selected" placeholder="Pilih Bentuk Benih"></v-select>
													</div>
												</div>
												<div class="col-md">
													<div class="form-group">
														<b for="">Negara</b>
														<v-select :options="list_negara" label="n_nama" v-model="negara_selected" placeholder="Pilih Negara"></v-select>
													</div>
												</div>
												<div class="col-md">
													<div class="form-group">
														<b for="">Search</b>
														<input type="text" class="form-control" id="search-table" placeholder="Search.." v-on:change="searchMaster" v-model="searchData" >
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-1">
											<br>
												<div class="btn btn-sm btn-primary" v-on:click="searchMaster"><i class="fa fa-filter"></i> Filter</div>
										</div>
									</div>
										<table class="table table-sm table-bordered">
											<thead class="bg-dark text-white">
												<tr>
													<th>No</th>
													<th>Kelompok Jenis Tanaman</th>
													<th>Sub Kelompok Jenis Tanaman</th>
													<th>Nama Latin</th>
													<th>Nama Umum</th>
													<th>Bentuk Benih</th>
													<th>Negara Asal</th>
													<th>Status</th>
													<th>Aksi</th>
												</tr>
											</thead>
											<tbody>
												<template>
                                                    <tr v-if="loadingTable">
                                                        <td colspan="6"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
                                                    </tr>
													<tr v-for="(row_data, key_aropt) in dataintable" :key="key_aropt" v-else>
														<td>
                                                            <template>
                                                                <div v-if="key_aropt+1 == 10">
                                                                    {{curent_page}}0
                                                                </div>
                                                                <div v-else>
                                                                    <template>
                                                                        <span v-if="curent_page-1 != 0">
                                                                            {{curent_page-1}}{{key_aropt+1}}
                                                                        </span>
                                                                        <span v-else>
                                                                            {{key_aropt+1}}
                                                                        </span>
                                                                    </template>
                                                                </div>
                                                            </template>
                                                        </td>
														<td>{{row_data.tanaman.tanaman_kategori_nama}}</td>
														<td>{{row_data.tanaman.tanaman_kategori_sub_nama}}</td>
														<td>{{row_data.tanaman.t_nama_latin}}</td>
														<td>{{row_data.tanaman.t_nama}}</td>
														<td>{{row_data.bentuk_benih.bb_nama}}</td>
														<td>{{row_data.negara.n_inisial}} - {{row_data.negara.n_nama}}</td>
														
														<td>
															<template>
				                                                <CBadge :color="getBadge(row_data.status)">
																	<div v-if="row_data.status">
																		Enable
																	</div>
																	<div v-else>
																		Disable
																	</div>
			                                                    </CBadge>
				                                            </template>
														</td>
														<td>
															<template>
                                                                <button v-if="row_data.status" class="btn btn-danger btn-sm" v-on:click="deleteData(row_data.id)"><i class="fa fa-trash"></i> Delete</button>
                                                                <button v-else class="btn btn-warning btn-sm" v-on:click="restoreData(row_data.id)"><i class="fa fa-refresh"></i> Restore</button>
                                                            </template>
                                                            <router-link :to="{name: 'EditAropt', params: {idAropt: row_data.id}}" class="btn btn-info btn-sm"><i class="fa fa-pencil"></i> Edit</router-link>
														</td>
													</tr>
												</template>
											</tbody>
										</table>
										<nav aria-label="pagination">
										    <ul class="pagination justify-content-start">
										        <!---->
										        <!-- {{ page.active ? 'active' : '' }} -->
										        <li v-for="(page, key_page) in paging" :key="key_page" v-bind:class="{ 'active': page.active }" class="page-item">
										        	<div v-on:click="toPage(page.url)" class="page-link c-page-link-number"> 
											        	<div v-html="page.label"></div>
											        </div>
										        </li>
										    </ul>
										</nav>
									</CCol>
								</CRow>
							</CCol>
						</CRow>
						<br />
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>
<script>
	const dataintable = [];
	export default {
		name: "AllAropt",
		components: {},
		data() {
			return {
		    	dataintable: [],
		    	paging: [],
		    	show_data_to: '',
		    	show_data_from: '',
				total_data: '',
				curent_page: '',
                loadingTable: true,
				kelompok_selected: [],
                subkelompok_selected: [],
                list_tanaman: [],
				list_bentuk_benih: [],
				bentuk_benih_selected: [],
				list_negara: [],
				negara_selected: [],
                searchData: '',
		    }
		},
		methods: {
			restoreData(id){
                // alert(id);
                Swal.fire({
                    title: "Apakah anda yakin?",
                    text: "Anda akan me-restore data tersebut.",
                    showDenyButton: false,
                    showCancelButton: true,
					confirmButtonClass: `btn btn-warning`,
                    confirmButtonText: `Proses`,
					cancelButtonClass: `btn btn-default`,
                    cancelButtonText: `Batal`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: '<i class="fa fa-refresh fa-spin"></i>',
                            text: "Loading...",
                            showConfirmButton: false,
                        });
                        axios
                            .delete(this.apiLink + "api/crud/aropt", {
                                headers: {
                                    Authorization: "Bearer " + this.access_token,
                                },
                                data: {
                                    id: id,
                                },
                            })
                            .then((response) => {
                                var res_data = response.data;
                                // console.log(res_data);
                                if (res_data.meta.code != "200") {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: res_data.data.message,
                                    });
                                } else {
                                    let timerInterval;
                                    Swal.fire({
                                        icon: "success",
                                        title: "Berhasil",
                                        text: "Data berhasil dikembalikan.",
                                        timer: 2000,
                                        timerProgressBar: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        didOpen: () => {
                                            timerInterval = setInterval(() => {
                                                const content = Swal.getContent();
                                                if (content) {
                                                    const b = content.querySelector("b");
                                                    if (b) {
                                                        b.textContent = Swal.getTimerLeft();
                                                    }
                                                }
                                            }, 100);
                                        },
                                        willClose: () => {
                                            clearInterval(timerInterval);
                                        },
                                    }).then((result) => {
                                        /* Read more about handling dismissals below */
                                        if (result.dismiss === Swal.DismissReason.timer) {
                                            this.searchMaster();
                                        }
                                    });
                                }
                            });
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                });
            },
			toPage(url) {
				this.loadingTable = true;
				// var numpage = url.replace(this.apiLink+'api/crud/aropt?page=','');
				axios.get(url, {
					headers: {
						Authorization: "Bearer " + this.access_token,
					},
				})
				.then((response) => {
					var res_aropt = response.data;
					var res_aropt_data = res_aropt.data[0];
					// console.log(res_aropt_data);
					if (res_aropt.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_aropt.data.message
						});
					}
					else{
						this.loadingTable = false;
						this.dataintable = res_aropt_data.data;
						this.paging = res_aropt_data.links;
						this.show_data_from = res_aropt_data.from;
						this.show_data_to = res_aropt_data.to;
						this.curent_page = res_aropt_data.current_page;
					}
				});
			},
			color(value) {
				let $color;
				if (value <= 25) {
					$color = "info";
				} else if (value > 25 && value <= 50) {
					$color = "success";
				} else if (value > 50 && value <= 75) {
					$color = "warning";
				} else if (value > 75 && value <= 100) {
					$color = "danger";
				}
				return $color;
			},
			getBadge(status) {
				if (status) {
					return "success";
				}
				else{
					return "danger";
				}
			},
			deleteData(id) {
                // alert(id);
                Swal.fire({
                    title: "Apakah anda yakin?",
                    text: "Anda tidak bisa mengembalikan data yang dihapus!",
                    showDenyButton: false,
                    showCancelButton: true,
					confirmButtonClass: `btn btn-danger`,
                    confirmButtonText: `Proses`,
					cancelButtonClass: `btn btn-default`,
                    cancelButtonText: `Batal`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: '<i class="fa fa-refresh fa-spin"></i>',
                            text: "Loading...",
                            showConfirmButton: false,
                        });
                        axios
                            .delete(this.apiLink + "api/crud/aropt", {
                                headers: {
                                    Authorization: "Bearer " + this.access_token,
                                },
                                data: {
                                    id: id,
                                },
                            })
                            .then((response) => {
                                var res_data = response.data;
                                // console.log(res_data);
                                if (res_data.meta.code != "200") {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: res_data.data.message,
                                    });
                                } else {
                                    let timerInterval;
                                    Swal.fire({
                                        icon: "success",
                                        title: "Berhasil",
                                        text: "Data berhasil dihapus.",
                                        timer: 2000,
                                        timerProgressBar: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        didOpen: () => {
                                            timerInterval = setInterval(() => {
                                                const content = Swal.getContent();
                                                if (content) {
                                                    const b = content.querySelector("b");
                                                    if (b) {
                                                        b.textContent = Swal.getTimerLeft();
                                                    }
                                                }
                                            }, 100);
                                        },
                                        willClose: () => {
                                            clearInterval(timerInterval);
                                        },
                                    }).then((result) => {
                                        /* Read more about handling dismissals below */
                                        if (result.dismiss === Swal.DismissReason.timer) {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                });
            },
			searchMaster(){
                this.loadingTable = true;
                axios
                .get(this.apiLink + "api/crud/aropt", {
                    params: {
                        search: this.searchData,
						tanaman_kategori_id: this.kelompok_selected?.id,
                        tanaman_kategori_sub_id: this.subkelompok_selected?.id,
						bentuk_benih_id: this.bentuk_benih_selected?.id,
                        negara_id: this.negara_selected?.id,
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_aropt = response.data;
					var res_aropt_data = res_aropt.data[0];
					// console.log(res_aropt_data);
					if (res_aropt.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_aropt.data.message
						});
					}
					else{
						this.loadingTable = false;
						this.dataintable = res_aropt_data.data;
						this.paging = res_aropt_data.links;
						this.show_data_from = res_aropt_data.from;
						this.show_data_to = res_aropt_data.to;
						this.curent_page = res_aropt_data.current_page;
					}
                });
            }
		},
		beforeCreate() {
			// console.log(this.access_token);
			axios.get(this.apiLink + "api/crud/aropt", {
				headers: {
					Authorization: "Bearer " + this.access_token,
				},
			})
			.then((response) => {
				var res_aropt = response.data;
				var res_aropt_data = res_aropt.data[0];
				// console.log(res_aropt_data);
				if (res_aropt.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_aropt.data.message
					});
				}
				else{
					this.loadingTable = false;
					this.dataintable = res_aropt_data.data;
					this.paging = res_aropt_data.links;
					this.show_data_from = res_aropt_data.from;
					this.show_data_to = res_aropt_data.to;
					this.total_data = res_aropt_data.total;
					this.curent_page = res_aropt_data.current_page;
				}
			});
			axios.get(this.apiLink + "api/master/tanaman", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_tanaman = response.data;
                var res_tanaman_data = res_tanaman.data;
                if (res_tanaman.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_tanaman.data.message
                    });
                }
                else{
                    this.list_tanaman = res_tanaman_data.master;
                    // console.log(res_tanaman_data.master);
                }
            });
			axios.get(this.apiLink + "api/master/bentuk_benih", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_bentuk_benih = response.data;
                var res_bentuk_benih_data = res_bentuk_benih.data;
                if (res_bentuk_benih.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_bentuk_benih.data.message
                    });
                }
                else{
                    this.list_bentuk_benih = res_bentuk_benih_data.master;
                    // console.log(res_tanaman_data.master);
                }
            });
			axios.get(this.apiLink + "api/master/negara", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_negara = response.data;
                var res_negara_data = res_negara.data;
                if (res_negara.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_negara.data.message
                    });
                }
                else{
                    this.list_negara = res_negara_data.master;
                    // console.log(res_tanaman_data.master);
                }
            });
		},
	};
</script>